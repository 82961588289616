export default {
  FACET_BUTTON_CLASS: 'cm_custom-button',
  BUTTON_PRIMARY_CLASS: 'action_button',
  BUTTON_SECONDARY_CLASS: 'action_button',
  OK_TEXT: 'Done',

  SEARCH_BOX_PLACEHOLDER: 'Find Something Fabulous',
  SEARCH_BOX_CLASS: 'search_box',
  SEARCH_BOX_INPUT_CLASS: 'search_box',
  SEARCH_BOX_BUTTON_CLASS: 'btn icon-fallback-text search-submit',
};
