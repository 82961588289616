export default {
  platform: 'shopify',
  ColorEngineFeature: true,
  stickyHeaderSelector: '.top_bar.top_bar--fixed-true',
  SearchRequestDefaults: {
    pageSize: 30,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'colorSearch/searchPage',
    },
    {
      name: 'SearchPageCollection',
      type: 'SearchPage',
      location: '.products',
      template: 'colorSearch/searchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
        'published_at': 'Date: Oldest to Newest',
        'published_at:desc': 'Date: Newest to Oldest',
        'title': 'Name',
        'on_sale:desc': 'On Sale',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
      loadMoreButtonClassName: 'action_button',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: 'div.search_box',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.top-icons.top-search',
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'ColorExtractorDialog',
      field: '_Color',
      template: 'colorSearch/colorExtractorDialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'SimpleColorPaletteDialog',
      field: '_Color',
      template: 'colorSearch/simplePaletteDialog',
    },
  ],
};
