import { SearchPage } from 'Components'
import TemplatecolorSearchsearchPage from 'Stores/_default-store/Templates/colorSearch/searchPage.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { ColorExtractorDialog } from 'Components'
import TemplatecolorSearchcolorExtractorDialog from 'Stores/_default-store/Templates/colorSearch/colorExtractorDialog.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { SimpleColorPaletteDialog } from 'Components'
import TemplatecolorSearchsimplePaletteDialog from 'Stores/_default-store/Templates/colorSearch/simplePaletteDialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
FacetPanel,
SearchResult,
SearchBoxDialogButton,
ColorExtractorDialog,
FacetDialog,
SearchBoxDialog,
SimpleColorPaletteDialog
} 
export const tplMap = {
  'colorSearch/searchPage': TemplatecolorSearchsearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'colorSearch/colorExtractorDialog': TemplatecolorSearchcolorExtractorDialog,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'colorSearch/simplePaletteDialog': TemplatecolorSearchsimplePaletteDialog
};